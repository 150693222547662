.scrub-slider {
  // utility
  position: relative;
  max-width: 100%;
  max-height: 100vw;
  height: 500px; //default to be overridden
  overflow: hidden;
  &:hover {
    cursor: pointer;
  }

  // slider content
  .scrub-content {
    display: block;
    position: absolute;
    top: 0;
    overflow: hidden;
    height: 100%;
    width: 100vw;
    &.scrub-left {
      left: 0;
      > div {
         left: 0;
      }
    }
    &.scrub-right {
      right: 0;
      > div {
         right: 0;
      }
    }
    > div {
      position: absolute;
      height: 100%;
      width: 100vw; // default to be overridden
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

  }
  // slider handle
  .sliding {
    position: absolute;
    left: 50%;
    height: 100%;
    width: 3px;
    z-index: 2;
    background: white;
    &.handleOn {
        &:before {
          content: '';
          width: 40px;
          padding-bottom: 40px;
          position: absolute;
          display: block;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          border: 3px solid #fff;
          border-radius: 100%;
          box-shadow: 0 0 2px 1px #000;
          z-index: 2;
        }
        span[class*=sliding-] {
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            position: absolute;
            top: 50%;
          &[class*=left] {
            left: 50%;
            transform: translate(-150%,-50%);
            border-right: 10px solid #000;
          }
          &[class*=right] {
            right: 50%;
            transform: translate(140%,-50%);
            border-left: 10px solid #000;
          }
        }
    }
  }
}
